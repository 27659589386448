<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalChangeDate.Main_Title')"
    modalSize="large"
    cssClassModalBody="is-hidden-overflow no-padding"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess || !showInput"
    :disableSaveButton="!enableSaveButton"
    :onClickCancel="onClickCancel"
    :onClickSave="saveReservation"
    :showModal="showModal"
    @closeModal="onClickCancel"
    @modalBodySize="(val) => { modalBodySize = val }"
  >
    <template v-slot:content>
      <div class="slider-wrapper">
        <transition-group :name="back ? 'slideback' : 'slide'">
          <!-- Choose transfer date -->
          <div
            v-if="showInput"
            :key="1"
            class="slideContent"
            :style="{height: modalBodySize.height + 'px'}"
          >
            <div class="transferDate-columns columns is-gapless">
              <div class="column is-one-third">
                <div class="has-padding">
                  <v-date-picker
                    mode="single"
                    v-model="startDate"
                    :value="formatDates(startDate)"
                    :is-inline="true"
                  >
                    <input
                      type="text"
                      class="input"
                      :id="'datepicker-trigger'"
                      readonly
                      :value="formatDates(startDate)"
                    />
                  </v-date-picker>
                </div>
              </div>
              <div class="column-spaces column">
                <div class="wrapper-spaces has-padding">
                  <div
                    class="has-margin-bottom-x2"
                    v-for="(meetingspace, index) in meetingspaces"
                    :key="meetingspace.Id"
                  >
                    <div class="columns is-multiline is-desktop is-vcentered">
                      <div class="column is-two-fifths">
                        <ReservationSpaceCard :reservationSpace="
                          getOriginalMeetingspace(meetingspace)
                        " />
                      </div>
                      <div class="column has-text-centered">
                        <ui-loader v-if="isLoading" />
                        <font-awesome-icon
                          v-if="!isLoading"
                          :icon="['fas', 'arrow-right']"
                        />
                      </div>
                      <div class="column is-two-fifths">
                        <ReservationSpaceCard
                          :reservationSpace="getOriginalOrUpdatedSpace(meetingspace)"
                          :showSpacePrice="meetingspace.IsAvailable"
                        />
                      </div>
                    </div>

                    <div v-if="
                      meetingspace.IsAvailable && !meetingspace.PriceHandled
                    ">
                      <article class="message is-info">
                         <div class="message-header">
                         <span>Price suggestion</span>
                         <button  @click="ignoreUpdatePrice(index)" class="delete" aria-label="delete"></button>
                           </div>      
                        <div class="message-body">
                          <span class="has-margin-top-x2" v-html="$t('Components.Reservation.ModalChangeDate.Label_PriceSuggestion') + getDisplayPrice(meetingspace.PricePerSeat)"></span>
                          <span class="has-margin-left">

                            <span>
                              <a
                                @click="updatePrice(meetingspace)"
                              >
                                <span v-text="$t('Components.Reservation.ModalChangeDate.Button_ApplyPrice')"></span>
                              </a>
                            </span>
                          </span>
                        </div>
                      </article>
                    </div>

                    <div
                      v-if="!meetingspace.IsAvailable"
                      class="notification is-warning"
                    >
                      <ul>
                        <li
                          v-if="showWarning(meetingspace.CheckString, 0)"
                          v-text="$t('Components.Reservation.ModalChangeDate.Warning_LocationClosedOnDay')"
                        ></li>
                        <li
                          v-if="showWarning(meetingspace.CheckString, 1)"
                          v-text="$t('Components.Reservation.ModalChangeDate.Warning_LocationClosed')"
                        ></li>
                        <li v-if="showWarning(meetingspace.CheckString, 2)">
                          <div class="wrapper">
                            <span v-text="$t('Components.Reservation.ModalChangeDate.Warning_SpaceNotAvailable')"></span>
                            <span>
                              <a
                                v-if="!meetingspace.IsAvailable"
                                @click="setShowSpaceAlternatives(meetingspace)"
                                class="button is-small is-success"
                                v-text="$t('Components.Reservation.ModalChangeDate.Button_ShowAlternatives')"
                              ></a>
                            </span>
                          </div>
                        </li>
                        <li v-if="showWarning(meetingspace.CheckString, 3)">
                          <div class="wrapper">
                            <span v-text="$t('Components.Reservation.ModalChangeDate.Warning_MinimumHoursNotMet')"></span>
                            <span>
                              <a
                                @click="ignoreWarning(meetingspace, 3)"
                                class="button is-small is-success"
                                v-text="$t('Components.Reservation.ModalChangeDate.Button_IgnoreWarning')"
                              ></a>
                            </span>
                          </div>
                        </li>
                        <li v-if="showWarning(meetingspace.CheckString, 4)">
                          <div class="wrapper">
                            <span v-text="$t('Components.Reservation.ModalChangeDate.Warning_NumberOfSeats')"></span>
                            <span><a
                                @click="ignoreWarning(meetingspace, 4)"
                                class="button is-small is-success"
                                v-text="$t('Components.Reservation.ModalChangeDate.Button_IgnoreWarning')"
                              ></a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Show alternatives -->
          <div
            v-if="showSpaceAlternatives"
            :key="2"
            class="slideContent-alternatives slideContent"
            :style="{height: modalBodySize.height + 'px'}"
          >
            <div class="backbar has-padding no-padding-bottom">
              <a @click="setShowInput">
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </span>
                <span v-text="$t('Form.Control.Back')"></span>
              </a>
              <hr />
            </div>

            <div class="columns-alternatives columns is-gapless is-mobile is-vcentered">
              <div class="column is-two-fifths">
                <div class="has-padding">
                  <ReservationSpaceCard :reservationSpace="selectedSpace" />
                </div>
              </div>
              <div class="column has-text-centered">
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
              </div>
              <div class="column-alternatives column is-two-fifths">
                <div class="has-padding no-padding-top">
                    <div class="title is-3">
                      {{ $t('Components.Reservation.ModalChangeDate.Subtitle_Alternatives')}}
                    </div>     
                  <ui-loader v-if="isLoadingAlternatives" />

                  <AvailableLocation
                    v-for="location in availablity.Locations"
                    :key="location.Id"
                    :availableLocation="location"
                    :meetingtypeId="reservation.MeetingtypeId"
                    :meetingSpaces="meetingspaces"
                    class="list-availableSpaces"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import availabilityProvider from '@/providers/availability'
import reservationProvider from '@/providers/reservation'
import format from 'date-fns/format'
import VCalendar from 'v-calendar'

const AvailableLocation = () =>
  import('@/components/Availability/AvailableLocation')
const ReservationSpaceCard = () =>
  import('@/components/Schedule/ReservationSpaceCard')

export default {
  components: {
    AvailableLocation,
    ReservationSpaceCard,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      back: false,
      dataFormat: 'D MMMM YYYY',
      endDate: null,
      isInitiated: false,
      isLoading: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      isLoadingAlternatives: false,
      meetingspaces: [],
      startDate: new Date(),
      selectedSpace: null,
      showSpaceAlternatives: false,
      showInput: true,
      slideContentContainerHeight: '',
      modalBodySize: {
        width: 0,
        height: 0
      }
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    startDateISO() {
      let dateString = ''

      if (this.startDate) {
        dateString = this.$options.filters.dateObjectIsoDateString(
          this.startDate
        )
      }

      return dateString
    },

    endDateISO() {
      let dateString = ''

      if (this.endDate) {
        dateString = this.$options.filters.dateObjectIsoDateString(this.endDate)
      }

      return dateString
    },

    allSpacesAvailable() {
      let allAvailable = false
      let checkCounter = 0

      if (this.meetingspaces && this.meetingspaces.length > 0) {
        for (let i = 0; i < this.meetingspaces.length; i++) {
          let ms = this.meetingspaces[i]

          if (ms.IsAvailable && ms.PriceHandled) {
            checkCounter = checkCounter + 1
          }
        }
      }

      if (checkCounter > 0 && checkCounter === this.meetingspaces.length) {
        allAvailable = true
      }

      return allAvailable
    },

    enableSaveButton() {
      let self = this
      let isEnabled = false

      if (self.allSpacesAvailable) {
        isEnabled = true
      }

      return isEnabled
    },
  },

  watch: {
    startDateISO(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.isInitiated && this.meetingtypeId !== 3) {
          this.checkAvailability()
        }
      }
    },
  },

  created() {
    this.startDate = this.reservation.StartDate
    this.endDate = this.reservation.EndDate

    EventBus.$on('spaceSelected', (space) => {
      this.updateSpaceWithAlternative(space)
    })
  },

  beforeDestroy() {
    EventBus.$off('spaceSelected')
  },

  mounted() {
    this.isInitiated = true
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    getDisplayPrice(price) {
      return this.$options.filters.toCurrency(
        price,
        this.reservation.CurrencyIso
      )
    },

    formatDates(startDate = '', endDate = '') {
      let formattedDates = ''
      if (startDate) {
        formattedDates = format(startDate, this.dataFormat)
      }
      return formattedDates
    },

    checkAvailability() {
      this.meetingspaces = []
      this.showSpaceAlternatives = false

      this.optionOptions = 0
      this.optionSet = 0
      this.optionVoucher = 0

      if (this.reservation.MeetingtypeId < 3) {
        this.endDate = this.startDate
      }

      availabilityProvider.methods
        .copyReservationCheckAvailability(
          this.reservation.Id,
          this.startDateISO,
          this.endDateISO
        )
        .then((response) => {
          this.meetingspaces = response.data

          for (let i = 0; i < this.meetingspaces.length; i++) {
            let orgMeetingspace = this.getOriginalMeetingspace(
              this.meetingspaces[i]
            )
            this.meetingspaces[i].LocationId = orgMeetingspace.LocationId
            this.meetingspaces[i].SpaceImage = orgMeetingspace.SpaceImage

            this.meetingspaces[i].PriceHandled = false
            if (
              orgMeetingspace.PricePerSeat ===
              this.meetingspaces[i].PricePerSeat
            ) {
              this.meetingspaces[i].PriceHandled = true
            }
          }
        })
    },

    showWarning(checkString, index) {
      let checks = checkString.split('#')

      return Number(checks[index]) === 0
    },

    ignoreWarning(meetingspace, index) {
      let checks = meetingspace.CheckString.split('#')
      checks[index] = 1

      let checkIndex = checks.findIndex((c) => c === 0)
      if (checkIndex === -1) {
        meetingspace.IsAvailable = true
      }

      meetingspace.CheckString = checks.join('#')
    },

    getOriginalMeetingspace(meetingspace) {
      let orgMeetingspace = this.reservation.Spaces.find(
        (s) => s.Id === Number(meetingspace.Id)
      )

      orgMeetingspace.LocationId = this.reservation.LocationId
      return orgMeetingspace
    },

    getOriginalOrUpdatedSpace(meetingspace) {
       if (!meetingspace.PriceHandled){
      let orgMeetingspace = this.reservation.Spaces.find(
        (s) => s.Id === Number(meetingspace.Id)
      )

      orgMeetingspace.LocationId = this.reservation.LocationId
      return orgMeetingspace
      } 
      return meetingspace
    },
    

    ignoreUpdatePrice(index) {
      let meetingspace = this.meetingspaces[index]
      let orgMeetingspace = this.getOriginalMeetingspace(meetingspace)

      this.meetingspaces[index].PricePerSeat = orgMeetingspace.PricePerSeat
      this.meetingspaces[index].PriceTotal = orgMeetingspace.PriceTotal
      this.meetingspaces[index].PriceHandled = true
    },

    updatePrice(space) {
      let spaceIndex = this.meetingspaces.findIndex((m) => m.Id === space.Id)
      if (spaceIndex > -1) {
        let updatedSpace = this.meetingspaces[spaceIndex]
        updatedSpace.PriceHandled = true
        

        this.meetingspaces[spaceIndex].PriceHandled = true
        Vue.set(this.meetingspaces, spaceIndex, updatedSpace)
      }
    },

    updateSpaceWithAlternative(space) {
      let self = this

      let spaceIndex = self.meetingspaces.findIndex(
        (m) => m.Id === self.selectedSpace.Id
      )

      if (spaceIndex > -1) {
        let newSpace = self.meetingspaces[spaceIndex]

        newSpace.SpaceId = space.SpaceId
        newSpace.SpaceName = space.SpaceName
        newSpace.SpaceImage = space.SpaceImage
        newSpace.StartDate = space.StartDate
        newSpace.StartMinutes = space.StartMinutes
        newSpace.EndDate = space.EndDate
        newSpace.EndMinutes = space.EndMinutes
        newSpace.SettingId = space.SettingId
        newSpace.PricePerSeat = space.PricePerSeat
        newSpace.PriceTotal = space.PriceTotal
        newSpace.Hash = space.Hash

        Vue.set(self.meetingspaces, spaceIndex, newSpace)

        self.ignoreWarning(self.meetingspaces[spaceIndex], 2)

        self.setShowInput()
      }
    },

    saveReservation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        let mReservation = JSON.parse(JSON.stringify(self.reservation))
        mReservation.Spaces = self.meetingspaces

        reservationProvider.methods
          .saveReservation(mReservation, false)
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              setTimeout(() => {
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
            self.isSavingSuccess = true
          })
      }
    },

    setShowSpaceAlternatives(space) {
      let self = this

      if (!self.isLoadingAlternatives) {
        self.availablity = {}
        self.isLoadingAlternatives = true

        self.back = false

        self.showInput = false
        self.selectedSpace = space
        self.selectedSpace.LocationId = this.reservation.LocationId
        self.showSpaceAlternatives = true

        let countryId = 0
        let channelId = self.reservation.ChannelId
        let searchterm = ''
        let settingId = 0

        let startDateISO = self.$options.filters.dateObjectIsoDateString(
          space.StartDate
        )
        let endDateISO = self.$options.filters.dateObjectIsoDateString(
          space.EndDate
        )

        availabilityProvider.methods
          .getAvailability(
            startDateISO,
            endDateISO,
            channelId,
            countryId,
            space.LocationId,
            searchterm,
            space.MeetingTypeIdeetingtypeId,
            space.Seats,
            space.StartMinutes,
            space.EndMinutes,
            settingId
          )
          .then((response) => {
            self.availablity = response.data
          })
          .finally(() => {
            self.isLoadingAlternatives = false
          })
      }
    },

    setShowInput() {
      this.back = true
      this.showInput = true
      this.showSpaceAlternatives = false
      this.availablity = {}
      this.selectedSpace = null
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template.scss";

.slider-wrapper {
  overflow: hidden;
  height: 80vh;
  max-height: 600px;
}

.slideContent {
  height: 100%;
  overflow: hidden;
  .transferDate-columns {
    height: 100%;

    .column {
      &:first-child {
      }
    }

    .wrapper-spaces {
      overflow: auto;
      overflow-x: hidden;
      height: 100%;

      .notification {
        padding: 10px;
        .wrapper {
          display: flex;
          align-items: center;
          span {
            &:first-child {
              flex-grow: 1;
              flex-shrink: 1;
            }
          }
        }
      }
    }
  }

  &.slideContent-alternatives {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .backbar {
      flex-grow: 0;
      flex-shrink: 0;
    }
    .columns-alternatives {
      height: 100%;
      overflow: hidden;

      .column-alternatives {
        overflow: auto;
        height: 100%;
      }
    }
  }
}
</style>
