import axios from 'axios'
import store from '../store'

let getAvailabilityToken = null

export default {
  methods: {
    async getAvailability(
      startDate,
      endDate,
      channelId,
      countryId,
      LocationId,
      searchTerm,
      meetingtypeId,
      seats,
      startTime,
      endTime,
      settingId = 0,
      spaceId = 0,
      voucherId = 0,
      tenderId = 0,
      widgetId = -100,
      language = 'en'
    ) {
      if (getAvailabilityToken) {
        getAvailabilityToken.cancel('Canceled by new request.')
      }
      getAvailabilityToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/availability`,
        {
          cancelToken: getAvailabilityToken.token,
          params: {
            q: searchTerm,
            channel: channelId,
            country: countryId,
            location: LocationId,
            meetingtype: meetingtypeId,
            seats: seats,
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime,
            setting: settingId,
            space: spaceId,
            isBackend: true,
            voucher: voucherId,
            tender: tenderId,
            widgetId: widgetId,
            language: language,
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async checkSpaceTimeAvailability(space) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/availability/time/check`,
        JSON.stringify(space),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async copyReservationCheckAvailability(reservationId, startDate, endDate) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/availability/copy/check`,
        {
          params: {
            endDate: endDate,
            reservationId: reservationId,
            startDate: startDate
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
